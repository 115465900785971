var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:{'text-area-scrollable-container': true, 'copied': _vm.copied}},[_c('overlay-scrollbars',{ref:"osComponentRef1",class:['Vue', 'text-area-scrollable'],attrs:{"options":{
          resize: 'none',
          className: 'os-theme-round-dark',
          paddingAbsolute: true,
          scrollbars: {
            autoHide: 'never'
          }
        }}},[_c('div',{class:{'text-area-html': true, 'copied': _vm.copied}},_vm._l((_vm.placeholder),function(paragraph){return _c('p',{key:paragraph},[_vm._v(" "+_vm._s(paragraph)+" ")])}),0),_c('textarea',{attrs:{"id":"text-area-input","readonly":""},domProps:{"innerHTML":_vm._s(_vm.placeholderString)}})])],1),_c('ButtonGroup',[_c('Button',{attrs:{"to":"/","id":"go-back-btn","button-style":"secondary","icon":"arrow-circle-left","flipped":"true"}},[_vm._v("Go Back")]),_c('Button',{attrs:{"iconOnly":"","id":"copy-text-btn","icon":"copy","tooltip-text":"Copy text","tooltip-clicked-text":"Copied!"},on:{"button-click":_vm.copyText}},[_vm._v(" Copy to Clipboard ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }